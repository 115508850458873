export const devices = {
  m1600: `@media (max-width: 1600px)`,
  m1280: `@media (max-width: 1280px)`,
  m1000: `@media (max-width: 1000px)`,
  m850: `@media (max-width: 850px)`,
  m768: `@media (max-width: 768px)`,
  m700: `@media (max-width: 700px)`,
  m550: `@media (max-width: 550px)`,
  m500: `@media (max-width: 500px)`,
  m450: `@media (max-width: 450px)`,
  m425: `@media (max-width: 425px)`,
  m375: `@media (max-width: 375px)`,
  m325: `@media (max-width: 325px)`,
};
